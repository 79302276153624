





































































































































import Vue from "vue";
import { mapActions, mapMutations } from "vuex";
import { ROOT_NOTIFICATION, ROOT_ERROR } from "@/store/modules/root/constants";
import {
  required_rules,
  get_email_rules,
  get_password_rules
} from "@/utils/global";
import { UPLOAD_FILES } from "@/store/modules/common/constants";
import { REGISTER_NEW_CANDIDATE } from "@/store/modules/recruiter/constants";

export default Vue.extend({
  name: "AddNewCandidate",
  components: {},
  data() {
    return {
      dialog: true,
      animation: require("@/assets/illustrations/analyzing.json"),
      loading: false,
      validate_candidate_form: false,
      register_form: {
        email: "",
        password: "",
        first_name: "",
        last_name: ""
      },
      show_password: false,
      profile: null as string | null | undefined | ArrayBuffer,
      profile_file: null as File | null
    };
  },
  created() {
    this.animation = JSON.stringify(this.animation);
  },
  methods: {
    required_rules,
    get_email_rules,
    get_password_rules,
    ...mapMutations({
      root_notification: ROOT_NOTIFICATION,
      root_error: ROOT_ERROR
    }),
    ...mapActions("common", {
      upload_files: UPLOAD_FILES
    }),
    ...mapActions("recruiter", {
      resgister_new_candidate: REGISTER_NEW_CANDIDATE
    }),
    async add_candidate() {
      this.loading = true;
      // Validate form
      if (this.validate_candidate_form && this.profile_file) {
        const obj = new FormData();
        obj.append("path", `avatar`);
        let profile_response = [];
        if (this.profile_file) {
          obj.append("file", this.profile_file);
          profile_response = await this.upload_files(obj);
        }
        // Upload Selected File
        const payload = {
          ...this.register_form,
          admin_add: true,
          avatar_uri: profile_response[0]?.location ?? ""
        };
        // Regiter new candidate
        const response = await this.resgister_new_candidate(payload);
        if (response) {
          this.root_notification("Candidate added successfully");
          this.$emit("fetch_list");
        } else this.root_error("Failed to add candidate");
      }
      this.loading = false;
      this.$emit("close");
    },
    upload_profile() {
      const file_uploader = this.$refs.profile_input as any;
      file_uploader.$refs.input.click();
    },
    process_selected_file(file: File) {
      this.profile_file = file;
      this.validate_resume_type_and_size(file);
    },
    validate_resume_type_and_size(file: File) {
      if (file) {
        if (
          !(
            (file.size <= 1048576 && file.type === "image/png") ||
            file.type === "image/jpeg" ||
            file.type === "image/jpg"
          )
        ) {
          this.root_error("Invalid file type or size");
        } else {
          const reader = new FileReader();
          reader.onload = (e) => {
            this.profile = e?.target?.result;
          };
          reader.readAsDataURL(file);
        }
      }
    }
  }
});
