


































































































































































































































































import Vue from "vue";
import AddNewCandidate from "@/components/recruiter/AddNewCandidate.vue";
import ChangePassword from "@/components/recruiter/ChangePassword.vue";
import DeleteItem from "@/components/shared/DeleteItem.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  DELETE_USER,
  USER_MANAGEMENT_LISTING
} from "@/store/modules/recruiter/constants";
import { UserListingPayload } from "@/store/modules/recruiter/interfaces";
import { user_consumption_time, get_name_initials } from "@/utils/global";
import { decrypt_password } from "@/utils/crypto";
import { SITE_DIRECTION } from "@/store/modules/common/constants";
import { SiteDirections } from "@/store/modules/common/interfaces";
import AutoCompleteUserSearch from "@/components/shared/AutoCompleteUserSearch.vue";
import { SearchUserObject } from "@/interfaces/shared/auto_complete_user_search";

export default Vue.extend({
  name: "UserManagement",
  components: {
    AddNewCandidate,
    DeleteItem,
    ChangePassword,
    AutoCompleteUserSearch
  },
  data() {
    return {
      page: 1,
      show_delete_dialog: false,
      delete_btn_loading: false,
      reset_password: false,
      headers: [
        { text: "N#", value: "id" },
        { text: "Name", value: "first_name" },
        { text: "Email", value: "email" },
        { text: "Password", value: "password" },
        { text: "Consumed Time", value: "user_data" },
        { text: "Actions", value: "actions" }
      ],
      add_candidate: false,
      selected_user: null as UserListingPayload | null,
      content_loading: false
    };
  },
  computed: {
    ...mapGetters("recruiter", {
      get_user_listing: USER_MANAGEMENT_LISTING
    }),
    ...mapGetters("common", {
      get_site_direction: SITE_DIRECTION
    }),
    SiteDirections() {
      return SiteDirections;
    }
  },
  mounted() {
    this.init_user_list(
      this.get_user_listing.page,
      this.get_user_listing.limit
    );
  },
  methods: {
    user_consumption_time,
    decrypt_password,
    ...mapActions("recruiter", {
      fetch_user_listing: USER_MANAGEMENT_LISTING,
      remove_user: DELETE_USER
    }),
    ...mapMutations("recruiter", {
      set_user_management_data: USER_MANAGEMENT_LISTING
    }),
    delete_selected_user(item: UserListingPayload) {
      this.selected_user = item;
      this.show_delete_dialog = true;
    },
    async process_pagination(page = 1) {
      this.add_candidate = false;
      this.reset_password = false;
      await this.init_user_list(page, this.get_user_listing.limit);
    },
    async init_user_list(page = 1, limit = 10) {
      this.content_loading = true;
      const payload = {
        page: page,
        limit: limit,
        selected_searched_user: this.get_user_listing.selected_searched_user
      };
      await this.fetch_user_listing(payload);
      this.content_loading = false;
    },
    async delete_user() {
      this.delete_btn_loading = true;
      if (this.selected_user && this.selected_user.email) {
        const results = await this.remove_user(this.selected_user.email);
        if (results) {
          this.delete_btn_loading = false;
          this.show_delete_dialog = false;
          return this.process_pagination(1);
        }
      }
      this.delete_btn_loading = false;
    },
    transform_eye_icon(icon: HTMLElement): HTMLElement {
      const color = "#00D2AA";
      for (let node of icon.children) node.setAttribute("stroke", color);
      return icon;
    },
    view_password(id: number, selected: boolean) {
      this.get_user_listing.results.find((item: any) => {
        if (item.id === id) {
          item.selected = selected;
        }
      });
    },
    change_password(item: UserListingPayload) {
      this.selected_user = item;
      this.reset_password = true;
    },
    get_name(name: string) {
      return get_name_initials(name);
    },
    async searched_user(users: SearchUserObject[]) {
      // User Ids Extracted
      const user_ids = users?.map((item: SearchUserObject) => item.id) ?? [];
      // Set User IDs in store
      this.set_user_management_data({
        ...this.get_user_listing,
        selected_searched_user: user_ids.length ? user_ids : []
      });
      // Fetch User Listing
      await this.process_pagination(1);
    }
  }
});
