








































































import Vue from "vue";
import { mapActions, mapMutations } from "vuex";
import { ROOT_NOTIFICATION, ROOT_ERROR } from "@/store/modules/root/constants";
import {
  required_rules,
  get_email_rules,
  get_password_rules
} from "@/utils/global";
import { CHANGE_USER_PASSWORD } from "@/store/modules/auth/constants";
import { REGISTER_NEW_CANDIDATE } from "@/store/modules/recruiter/constants";
import {
  maxLength,
  minLength,
  required,
  sameAs
} from "vuelidate/lib/validators";
import { get_password_validator_regex } from "@/utils/global";
import { TranslateResult } from "vue-i18n";
export default Vue.extend({
  name: "ChangePassword",
  components: {},
  data() {
    return {
      dialog: true,
      loading: false,
      password: null,
      confirm_password: null,
      show_password: false,
      show_confirm_password: false
    };
  },
  props: {
    user_id: {
      type: Number,
      required: true
    },
    name: {
      type: String
    }
  },
  methods: {
    required_rules,
    get_email_rules,
    get_password_rules,
    ...mapMutations({
      root_notification: ROOT_NOTIFICATION,
      root_error: ROOT_ERROR
    }),
    ...mapActions("auth", {
      reset_password: CHANGE_USER_PASSWORD
    }),
    ...mapActions("recruiter", {
      resgister_new_candidate: REGISTER_NEW_CANDIDATE
    }),
    async add_candidate() {
      this.$v.$touch();
      this.loading = true;
      const payload = new FormData();
      if (this.password && this.user_id) {
        payload.append("password", `${this.password}`);
        payload.append("user_id", this.user_id.toString());
        const result = await this.reset_password(payload);
        if (result) this.root_notification("Password changed successfully");
        else this.root_error("Failed to change password");
        this.$emit("fetch_list");
      } else this.loading = false;
    },
    password_field_errors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.password?.$dirty) return errors;
      !this.$v.password?.required && errors.push(this.$t("rules .required"));
      !this.$v.password?.min && errors.push(this.$t("rules.password.min"));
      !this.$v.password?.max && errors.push(this.$t("rules.password.max"));
      !this.$v.password?.valid &&
        errors.push(this.$t("rules.password.combine"));
      return errors;
    },
    confirm_password_field_errors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.confirm_password?.$dirty) return errors;
      !this.$v.confirm_password?.required &&
        errors.push(this.$t("rules.required"));
      !this.$v.confirm_password?.min &&
        errors.push(this.$t("rules.password.min"));
      !this.$v.confirm_password?.max &&
        errors.push(this.$t("rules.password.max"));
      !this.$v.confirm_password?.valid &&
        errors.push(this.$t("rules.password.combine"));
      !this.$v.confirm_password?.match &&
        errors.push(this.$t("rules.password.match"));
      return errors;
    }
  },
  validations() {
    return {
      password: {
        required,
        min: minLength(8),
        max: maxLength(16),
        valid: (value: string) => get_password_validator_regex().test(value)
      },
      confirm_password: {
        required,
        min: minLength(8),
        max: maxLength(16),
        valid: (value: string) => get_password_validator_regex().test(value),
        match: sameAs("password")
      }
    };
  }
});
